@import '../../assets/scss/colors';

.secunda {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 100;

  .cell {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 600px;
    z-index: 30;
    background-image: linear-gradient(180deg, #ffffff00 0%, #91d1ffcd 33%, #e2f3ffcd 50%, #b482ffcd 75%, #ffffff00 100%);

    
    .row {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 1200px) {
        display: flex;
      }
    }

    .title {
      font-family: 'Lexend', sans-serif;
      text-align: center;
      margin: 0 auto;
      line-height: 1.1;


      span {
        display: inline-flex;
        justify-content: center;
        transition: all 0.5s;
        transform-origin: 50% 50%;
        font-weight: 600;
        line-height: 1.1;
      }

      .bit-1 {
        font-size: 1.75rem;

        color: #000;
      }

      .bit-2 {
        color: #000;
      }

      .bit-3 {
        color: $violet_600;
      }

      .two {
        width: 100%;
        font-size: 3.5rem;
      }

      @media (min-width: 1200px) {

        span.two {
          font-size: 4.5rem;
          letter-spacing: -4px;
        }

      }

    }

    .tagline {
      font-family: 'Lexend', sans-serif;
      color: #000;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -.5px;
      text-align: center;
      margin: 1.5rem auto;
    }

    .subtitle {
      font-family: 'Lexend', sans-serif;
      color: #000;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: -.5px;
      text-align: center;
      margin: 1.5rem auto;
    }

  }

  @keyframes pan {
    0% {
      background-position: 0 0;
    }

    25% {
      background-position: 100% 0;
    }

    50% {
      background-position: 100% 0;
    }

    75% {
      background-position: 0 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    transform-origin: center;
    background-size: auto 100vh;
    background-repeat: repeat-y;
    background-image: url('../../assets/images/infra.jpg');

    @media (min-width: 1200px) {
      background-size: 120%;
    }
    &.animated {
      animation: pan 90s 0.5s infinite;
    }
  }
}
