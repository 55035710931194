@import '../assets/scss/main';


.Footer {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  z-index: 300;
  color: white;
  background-color: black;
  padding: 1rem;
  text-align: center;

  .copy {
    display: flex;
    font-size: 0.75rem;
    text-align: center;
    margin: 0 auto 1rem auto;

    a {
      margin-right: 0.5rem;
      display: inline-flex;

      &:first-of-type {
        margin-left: 0.5rem;
      }
    }
  }

  a {
    color: white;
    text-decoration: underline;
  }

  .row {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    z-index: 300;

  }

  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;

    svg {
      padding: 1rem;
      width: 100%;
      height: 100%;
      fill: black;
    }

  }

  .menu-footer {

    display: block;
    padding: 0.5rem;
    width: 100%;

    a {
      display: inline-block;
      padding: 0 0.25rem;
    }

    @media (min-width: 1200px){
      
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 1rem;

    a {
      display: inline-flex;
      padding: 0 0.5rem;
    }
  }

  }
}