@import "./assets/css/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp");



:root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body { /* except colors */
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 300;

  font-size: 16px;
  font-size: 100%;
  font-size: 1rem;

  line-height: 1.618;

  -webkit-font-smoothing: antialiased;

  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}
