// Effects

@import 'main';


.appear {
  animation: appear 3.5s forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.to-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  opacity: 1;
}
.to-right {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100vw);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.splash {
  background-image: linear-gradient(309deg, #ffda08, #33acff, #d2199a, #ff2828, #ffda08);
  background-size: 600% 600%;
  animation: appear 2s ease, gradient-moving 12s ease infinite;

  &:hover {
    cursor: pointer;
    // filter: hue-rotate(90deg);
    background-image: none;
    background-color: #33acff;
    color: black;
  }
}

.breathe {
  animation: breathe 5s ease infinite;
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.changing-color {
  animation: changing-color 5s ease infinite;
}

@keyframes changing-color {
  0% {
    color: #ff2828;
  }

  25% {
    color: #ffda08;
  }

  50% {
    color: #33acff;
  }

  75% {
    color: #d2199a;
  }

  100% {
    color: #ff2828;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes gradient-moving {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@keyframes gradient-rotation {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes inflate-then-burst {
  0% {
    transform: scale(1);
    opacity: 1; 
  }

  80% {
    transform: scale(1.5);
    opacity: 1;
  }
  81% {
    transform: scale(1.7);
    opacity: 0.5;
  }
  82% {
    transform: scale(1.5);
    opacity: 0.3;
  }

  100% {
    transform: scale(10);
    opacity: 0;    
  }
}

/* Clip text element */
.clip-text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Background */
.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
}

.clip-text_two {
  background-image: linear-gradient(309deg, #ff2828, #ffda08, #ff5100, #ff08de, #33acff, #d2199a, #ff4e08, #ffae00, #ff2828);
  background-size: 600% 600%;
  animation: gradient-moving 12s ease infinite;
}



  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;


  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    15% {
      background-position: 50% 50%;
    }
    50% {
      background-position: 100% 0%;
    }
    75% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

 
