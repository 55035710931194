@import '../../assets/scss/main';

.quarta {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 100px - 2rem);
  align-items: center;
  justify-content: flex-start;
  background-color: $violet_200;


  .boxed {
    display: flex;
    flex-direction: column;
    z-index: 300;
    width: 100%;

    @media (min-width: 1200px) {
      flex-direction: row;
      align-items: stretch;
      height: 45vh;
    }

    .one {
      width: 100%;
      padding: 1rem;
      margin-bottom: 1rem;

      @media (min-width: 1200px) {
        background-color: rgba($color: #fff, $alpha: 0.4);
        padding: 2rem 3rem;
        width: 35%;
        margin: 2rem 2rem 0 0; 
      }

      h2 {
        font-family: 'Lexend', sans-serif;
        font-size: 2rem;
        letter-spacing: -1px;
        line-height: 1.2;
        margin: 2rem 0 1rem;
        text-align: left;
        color: #000;

    
        @media (min-width: 1200px) {
          font-size: 3rem;
          font-weight: 900;
          letter-spacing: -2px;
          margin: 2rem 0;
        }
      }
    
      .intro {
        font-size: 0.9375rem;
        font-weight: 600;
        color: #000;
        margin: 1rem 0;
      }

      .email.large {
        display: flex;
        justify-content: center ;
        padding: 0.5rem 1rem;
        margin: 1rem 0;
        font-size: 1.5rem;
        font-weight: 200;
        color: #fff;
        background-color: $indigo_600;
      }
    }

    .two {
      width: 100%;
      background-color: rgba($color: $blue_700, $alpha: 0.4);

      .cf {

        .contact-btn {
          display: block;
          font-family: 'Lexend', sans-serif;
          font-size: 1rem;
          font-weight: 500;
          color: #000;
          padding: 0.55rem 2rem;
          margin: 0.5rem;
          cursor: pointer;
          border: 8px solid #fff;
          transition: all 0.35s;
          background-color: $violet_500;
        }
  

        input[type="text"],
        textarea {
          display: inline-block;
          background-color: rgba($color: #FFF, $alpha: 0.8);

        }

        .contact-btn {
          margin: 0;
          color: #fff;
        }
      }


      @media (min-width: 1200px) {
        width: 65%;
        padding: 2rem;
        margin-top: 2rem;
        background-color: rgba($color: #FFF, $alpha: 0.3);

        .cf {
          width: 60%;
          padding: 2rem;

          input[type="text"],
          textarea {
            background-color: rgba($color: #FFF, $alpha: 0.6);
          }

        }

      }
    }

    .note {
      font-size: 0.875rem;
      font-weight: 300;
      color: $grey_900;
      margin-top: 2rem;
    }
  }

  .bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url('../../assets/images/bg--n.jpg');
    background-repeat: no-repeat;
    opacity: 0.5;

    @media screen and (min-width: 1200px) {
      opacity: 1;
    }
  }
}