@import '../../assets/scss/main';

.cf {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-family: var(--font-fam-main);

  @media (min-width: 1200px) {
    padding: 1rem 0;
    margin: 0 auto;
  }
  .form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0.5rem 0;
    width: 100%;

    label {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-size: 0.725rem;
      margin: 0 0 0.5rem 0;
      color: black;

      @media (min-width: 1200px) {
        width: 100%;
      }

      span {
        width: 120px;
      }

      .mighty {
        width: 120px;
        font-size: .75rem;
      }
    }

    input,
    textarea {
      display: block;
      width: 100%;
      padding: 1rem;
      border: 1px solid #ddd;
    }

    textarea {
      height: 100px;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }

  .mighty-sumbit {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #000;
    color: white;
    font-size: 0.875rem;
    margin: 1rem 0;
    border: 0;
    cursor: pointer;
  }
}
