@import '../../assets/scss/main';



.prima {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 120px);
  color: #000;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;

  @media (min-width: 1200px) {
    height: 100vh;
  }


  .content {
    position: relative;
    margin: 10vh 1.75rem 0;
    padding: 1rem;
    max-width: 300px;
    background-color: rgba(255, 255, 255, 0.65);
    color: #000;
    text-align: center;

    @media (min-width: 1200px) {
      background-color: transparent;
      box-shadow: none;
      max-width: 500px;
      margin: 0 auto;
    }


    h2.tagline {
      margin: 1.5 0;
      font-family: 'Lexend', sans-serif;
      line-height: 1.1;

      span {
        display: inline-flex;

        &.bit-1 {
          color: $violet_600;
          font-size: 2.5rem;
          font-weight: 600;
          letter-spacing: -2px;
        }

        &.bit-2 {
          color: #000;
          font-size: 2.5rem;
          font-weight: 600;
          letter-spacing: -2px;
        }

        &.bit-3 {
          margin-top: 1rem;
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      @media (min-width: 1200px) {
        font-size: 1.5rem;
        font-weight: 500;

        span {
          display: inline;

          &.bit-1,
          &.bit-2,
          &.bit-3 {
            color: #000;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: initial;
            letter-spacing: -1px;
          }
        }
      }
    }

    .lead {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.6;
      margin: 0 0 1rem;
      color: #000;

      @media (min-width: 1200px) {
        font-size: 1rem;
      }
    }
  }


  .cell {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-bottom: 15vh;
    z-index: 30;

    @media (min-width: 1200px) {
      width: 70vh;
      height: 70vh;
      min-width: 600px;
      min-height: 600px;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      text-align: center;
      border-radius: 50%;

      background-image: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(191, 219, 255, 0.2) 100%);
    }

    @media (min-width: 1920px) {
      width: 60vh;
      height: 60vh;
    }
  }

  .logo {
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }



  .logo {
    font-family: 'Lexend', sans-serif;
    text-align: center;
    margin: 0 auto;
    line-height: 1.1;

    @media (min-width: 1200px) {
      margin: 20vh auto 3rem;
    }

    span {
      display: inline-flex;
      justify-content: center;
      transition: all 0.5s;
      transform-origin: 50% 50%;
      letter-spacing: -2px;
      font-weight: 600;
      font-size: 2rem;
      line-height: 1.1;

      &.bit-4 {
        color: $violet_600;
      }

      .bit-5 {
        color: #000;
      }
    }
  }

  @keyframes pan {
    0% {
      background-position: 0 0;
    }

    25% {
      background-position: 100% 0;
    }

    50% {
      background-position: 100% 0;
    }

    75% {
      background-position: 0 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    transform-origin: center;
    background-image: url('../../assets/images/xx2.jpg');
    background-position: 0 0;
    background-size: auto 100vh;

    &.animated {
      animation: pan 45s 0.5s infinite;
    }

    @media (min-width: 1200px) {
      background-size: 110% auto;

      &.animated {
        animation: pan 90s 0.5s infinite;
      }
    }
  }
}

.Front .prima {
  height: calc(100vh + 80px);
}