@import '../../assets/scss/main';


.compare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: $violet_200;
  background-image: linear-gradient(110deg, $violet_300 0%, $indigo_200 35%, $blue_200 60%,  $violet_300 100%);
  font-weight: 400;
  font-size: 1rem;



  @media (min-width: 1200px) {
    padding: 2rem 0 4rem;
    // background-image: url('../../assets/images/lights-bg-5.jpg');
    background-size: cover;
  }


  h2.level2 {
    font-family: 'Lexend', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin: 1rem 0 2rem;
    color: $grey_900;
    letter-spacing: -0.75px;

    @media (min-width: 1200px) {
      font-size: 3rem;
      letter-spacing: -2px;
      margin: 2rem 0;
    }

    span {
      display: inline-flex;

      &.continuation {
        display: block;
        margin-top: 0.75rem;
        font-size: 1.5rem;
        letter-spacing: 0;
        font-weight: 300;
      }
    }
  }


  .comparison-table {
    width: 100%;
    max-width: 600px;
    margin-bottom: 2rem;

    @media screen and (min-width: 1200px){
      margin-bottom: 0;
    }
  } 


  .comparison-header,
  .comparison-row {
    display: flex;
    align-items: stretch;
   }

  .comparison-header {

    .feature-column,
    .column {
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      color: $grey_700;
      font-size: .75rem;
      padding: 0 0 .75rem;
      text-transform: uppercase;
    }
  }

  .feature-column,
  .column {
    height: 60px;
    border-right: 1px solid $grey_300;
    border-bottom: 1px solid $grey_300;

  }

  .feature-column {
    display: block;
    color: #000;
    font-weight: 400;
    width: 200px;
    margin-right: 20px;
    padding-top: 1rem;
    border-bottom: 1px dotted #000;
    width: 50%;

  }

  .column {
    display: inline-flex;
    justify-content: center;
    background-color: $violet_500;
    width: 25%;

    &.true{
      background-color: $violet_600;
    }
    &.false{
      background-color: rgba($violet_600, 0.5);
    }
  }

  .comparison-header .column:last-of-type {
    background-color: transparent;
  }

  .icon-check {
    color: white;
    font-size: 2.5em;
  }

  .icon-times {
    color: #000;
    font-size: 2.5em;
  }
}