@import '../assets/scss/main';


.menu--mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;
  z-index: 100;

  @media (min-width: 1200px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  .menu-item {
    display: flex;
    padding: 0.5rem 0;
    margin: 0 0.5rem;
    margin-left: 3rem;
    line-height: 3;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px solid white;
    color: #999;

    .inner {
      display: inline-block;
      margin-right: 1rem;
      color: white;
    }


    a {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      height: 100%;

      &:hover {
        color: $violet_600;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }


    .on {
      color: #fff;

      &:hover {
        color:  $violet_600;
      }
    }

  }

}

