
@import '../../assets/scss/main';

.items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1rem;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
  }


  .item-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;

    @media (min-width: 1200px) {
      margin: 0 1rem 0 0;
      padding: 1rem 0 0 0;
      width: 33.33;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .aas-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $violet_600;
      background-size: 200% 200%;
      background-position: 0% 0%;
      background-image: radial-gradient(circle, $sky_400 0%, $violet_700 100%);
      // animation: gradient 20s linear infinite;
      color: white;
      width: 70px;
      height: 70px;
      padding: 0.25rem;

      .material-symbols-sharp {
        font-size: 64px;
        font-variation-settings:
          'FILL' 0,
          'wght' 200,
          'GRAD' 0,
          'opsz' 64,
        ;
      }

      @media (min-width: 1200px) {
        width: 88px;
        height: 88px;

        .material-symbols-sharp {
          font-size: 72px;
          font-variation-settings:
            'FILL' 0,
            'wght' 200,
            'GRAD' 0,
            'opsz' 96,
          ;
        }
        
      }
    }

    h3 {
      font-family: 'Lexend', sans-serif;
      font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.2;
      text-align: center;
      margin: 1rem 0;
      color: #44403c; //stone 700
    }

    p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.6;
      text-align: center;
      color: #44403c; //stone 700
    }
  }
}
