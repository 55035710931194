@import '../../assets/scss/main';

.nav-next {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    z-index: 300;

  .nextBtn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);  
    bottom: 4rem;
    width: 80px;
    height: 80px;
    margin: 1rem 0;
    border-radius: 50%;
    background-color: #000;
    border: 4px solid #fff;
    color: #fff;
    font-size: 1.5rem;
    z-index: 300;
    cursor: pointer;

    .material-symbols-sharp {
      color: #fff;
      display: inline-flex;
    }

    svg {
      color: #fff;
    }

    &:hover {
      background-color: $violet_600;
    }
  }



}