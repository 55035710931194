@import '../../assets/scss/main';


.bg-700 {
  background-color: $violet_700;
  color: #fff;

  .cta-btn {
    background-color: #000;
  }
}
.bg-000 {
  background-color: #000;
  color: #fff;

  .cta-btn {
    background-color: $violet_700;
  }
}

.cta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-repeat: repeat-x;
  width: 100%;
  padding: 2rem 1rem;
  background-image: none;
  background-size: cover;

  @media (min-width: 1200px){
    height: 300px;
  }

  h2 {
    font-family: 'Lexend', sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    letter-spacing: -.15px;
    padding: 0 1rem;
    margin: 0 0 1rem;

    @media (min-width: 1200px) {
      font-size: 2.25rem;
      letter-spacing: -1px;
      margin: 0 0 2rem;
    }
  }

  .cta-text-before {
    font-family: 'Lexend', sans-serif;
    font-size: 0.875rem;
    font-weight: 200;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -.15px;
    padding: 0 1rem;
    margin: 0 0 1rem;
    color: #fff;

    @media (min-width: 1200px) {
      font-size: 1rem;
      letter-spacing: 2px;
    }
  }

  .button.cta-btn {
    display: block;
    font-family: 'Lexend', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    padding: 1rem 2rem;
    cursor: pointer;
    border: 10px solid #fff;
    transition: all 0.35s;

  }
}