@import '../assets/scss/main';


.topbar {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 122;
  background-color: #000;
  z-index: 300;

  @media (min-width: 1200px) {
    height: 80px;
  }

  .topbar--inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    justify-content: space-between;
  }

  .logo-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;

    .logo {
      margin-right: 1rem !important;
    }
  }

  .group {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
  }

  .burger {
    position: relative;
    display: inline-flex;
    margin: 0 .75rem 0 1rem;
    z-index: 200;

    &>.material-symbols-sharp {
      font-size: 2rem;
      color: #fff;
    }

    &:hover {
      cursor: pointer;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }


  .site-tagline {

    display: none;

    @media (min-width: 1200px) {
      position: relative;
      display: inline-flex;
      top: 3px;
      color: #fff;
      text-transform: uppercase;
      font-size: 0.75rem;
      justify-content: center;
      align-items: center;
      margin-right: 3rem;
    }
  }

  .contact-btn {
    display: none;

    @media (min-width: 1200px) {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 500;
      background-color: $violet_700;
      border: 5px solid #fff;
      color: #fff;
      padding: 0.25rem 1rem;
      cursor: pointer;
      transition: all 0.35s;
      
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}