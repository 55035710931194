@import '../assets/scss/main';

.menu--desktop {
  display: none;

  @media (min-width: 1200px) {

    position: relative;
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 1rem;
    margin: 0 1rem 0 2rem;
    color: #fff;
    font-family: 'Lexend', sans-serif;
    font-size: 0.9875rem;
    font-weight: 500;
    letter-spacing: 0.125px;

    a {
      display: inline-block;
      margin: 0 0.5rem;
      color: inherit;
      text-decoration: none;
    }
  }
}
