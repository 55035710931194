@import '../../assets/scss/main';

$violet_900: #111111;

.logo {
  font-family: 'Lexend', sans-serif;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
  margin: 0 1rem;

  &.small {
    font-size: 1.25rem;
  }

  &.medium {
    font-size: 2rem;
    letter-spacing: -2px;
  }

  span {
    font-size: 1.125rem;

    &.one {
      color: $violet_700;
    }

    &.two {
      color: #000;
    }
  }

  &.inverse {

    span.one {
      color: $violet_300;
    }

    span.two {
      color: $violet_500;
    }
  }

}