@import '../assets/scss/main';


.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: white;
  background-image: url('../assets/images/404.jpg');

  h1 {
    font-family: 'Lexend', sans-serif;
    font-size: 5vh;
    font-weight: 900;
    margin: 0;
  }

  button {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #000;
    border: 8px solid #fff;
    color: white;
    font-size: 0.875rem;
    margin: 1rem 0;
    font-family: 'Lexend', sans-serif;
    cursor: pointer;

    a {
      color: white;
    }
  }
}
