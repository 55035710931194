@import './assets/scss/main';

body { // colors
  color: #000;
  background-color: #f5f5f4; 
}

.page {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

.box-shadow-gentle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);   
}

.hidden {
  display: none;
}

.button {
  display: block;
  font-family: 'Lexend', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: black;
  padding: 1rem 2rem;
  cursor: pointer;
  border: 10px solid #fff;
  transition: all 0.35s;


  a {
    color: #fff;
    width: 100%;
    height: 100%;
    display: block;

  }

}


.blue-300 {
  color: #93c5fd;
}

.blue-400 {
  color: #60a5fa;
}

.blue-500 {
  color: #3b82f6;
}

.blue-600 {
  color: #2563eb;
}

.blue-700 {
  color: #1d4ed8;
}


.bg-blue-300 {
  background-color: #93c5fd;
  color: #000;
}

.bg-blue-400 {
  background-color: #60a5fa;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-700 {
  background-color: #1d4ed8;
  color: #fff;
}

.standalone {
  .page {
    margin-top: 80px;
  }
  .nextBtn {
    bottom: 6rem;
  }
}

.boxed {
  padding: 1rem;
  width: 100%;

  @media (min-width: 1200px) {
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
  }
}




.mask-256 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 256px;
  height: 256px;
  margin: 1rem auto;

  &.a {
    clip-path: path("M230.93,82.42C209.39,38.45,166,6.22,119.76,1.15c-37.73-6.08-62.56,12.6-82.83,38.31C16.65,65.18,5.87,97.96,1.52,131.02c-2.3,17.46-2.77,35.97,4.28,51.97,8.93,20.28,28.44,33.19,48.29,41.22,53.41,21.58,118.98,13.22,160.55-28.13,13.01-12.93,23.66-29.14,27.47-47.53,4.62-22.31-1.24-45.82-11.18-66.13Z");
  }
  &.b {
    clip-path: path("M195.63,20.19c-23-15.2-29.16-15.9-56.19-19.04C77.48,1.93,27.25,39.49,7.81,101.25c-6.65,21.13-8.42,44.75-.61,65.44,10.26,27.18,35.77,45.6,62.72,53.02,26.96,7.45,56.63,17.68,84.28,14.15,21.55-2.76,43.08-19.3,59.21-34.55,10.7-10.12,17.38-24.17,21.85-38.54,15.61-50.04,2.82-112.49-39.62-140.57Z");
  }
  &.c {
    clip-path: path("M149.57,18.55c47.32,6.59,106.68,24.89,73.78,107.59-32.91,82.7-101.04,97.38-133.95,106.43-32.91,9.05-71.56-12.47-57.9-61.18C43.44,128.75,7.66,75.16,11.09,40.07,14.52,4.97,92.78,10.61,149.57,18.55Z");
  }
}



.mask-512 {
  @media (min-width: 1200px){
    position: relative;
    display: block;
  
    width: 512px;
    height: 512px;

    &.a {
    clip-path: path("M319.92,13.75c109.13,15.19,246.05,57.39,170.15,248.13-75.89,190.73-233.03,224.59-308.92,245.46-75.89,20.87-165.04-28.76-133.54-141.11C75.15,267.91-7.37,144.31.54,63.38,8.44-17.55,188.94-4.56,319.92,13.75Z");
    }
    &.b {
      clip-path: path("M413.64,41.76C364.49,8.42,351.33,6.89,293.58,0,161.18,1.7,53.86,84.09,12.32,219.55c-14.21,46.34-17.99,98.16-1.31,143.54,21.93,59.62,76.42,100.03,134.02,116.3,57.59,16.33,121.01,38.77,180.07,31.03,46.05-6.05,92.05-42.33,126.52-75.79,22.87-22.21,37.14-53.01,46.68-84.54,33.36-109.75,6.03-246.73-84.66-308.33Z");
    }
    &.c {
      clip-path: path("M475.65,180.35C432.57,92.4,345.78,27.93,253.31,17.81c-75.46-12.16-125.12,25.19-165.67,76.62-40.55,51.43-62.11,116.98-70.82,183.11-4.6,34.91-5.54,71.95,8.56,103.95,17.85,40.57,56.87,66.39,96.58,82.44,106.82,43.17,237.97,26.44,321.1-56.26,26.01-25.87,47.33-58.28,54.94-95.06,9.25-44.62-2.47-91.63-22.35-132.26Z");
    }
    
  }
} 

