@import '../../assets/scss/main';


.tertia {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 150;

  @media (min-width: 1200px) {
    padding: 2rem 0;
  }

  h1.title {
    font-family: 'Lexend', sans-serif;
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -2px;
    margin: 0;

    @media (min-width: 1200px) {
      font-size: 4.5rem;
      font-weight: 600;
      letter-spacing: -4px;
    }
  }

  h2.level1 {
    font-family: 'Lexend', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -.25px;
    line-height: 1.2;
    text-align: center;
    margin: 2rem 0 0;
    color: #1c1917; //stone 900

    @media (min-width: 1200px) {
      font-size: 3rem;
      font-weight: 700;
      letter-spacing: -2px;
      margin: 2rem 0;
    }
  }

  .compare+h2.level1 {
    @media (min-width: 1200px) {
      margin-top: 4rem;
    }
  }

  .one {
    display: block;
    width: 20%;
    margin-bottom: 2rem;
  }

  .benefits {
    padding-bottom: 6rem;
  }


  .mask-256.two {
    top: -7rem;
  }
}

.standalone .tertia .next-btn {
  bottom: 5rem;
}