@import '../../assets/scss/main';


.animation-flip-word {
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;


    .title {
      position: relative;
      display: flex;
      white-space: nowrap;
      width: 100%;
      margin: 0;
      font-family: 'Lexend', sans-serif;
      font-size: 3.25rem;
      font-weight: 500;

      span {
        color: #fff;
        display: inline-flex;
        transition: all 0.5s;
        transform-origin: 50% 50%;
        letter-spacing: -3px;

        &.one {
          width: 40%;
          justify-content: flex-end;
          flex: 0 0 40%;
          color: #000;
        }

        &.two {
          width: 50%;
          flex: 0 1 50%;
          padding: 0 1rem;
          margin: 0 1rem;


          &.sky {
            background-color: $sky_500;
            animation: flip-1 0.5s;
          }

          &.blue {
            background-color: $blue_500;
            animation: flip-2 0.5s;
          }

          &.indigo {
            background-color: $indigo_500;
            animation: flip-3 0.5s;
          }

          &.violet {
            background-color: $violet_500;
            animation: flip-4 0.5s;
          }
        }

      }
    }
  }

  @keyframes flip-1 {
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  @keyframes flip-2 {
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  @keyframes flip-3 {
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  @keyframes flip-4 {
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

}